import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { BiCalendar, BiPhoneCall } from 'react-icons/bi'
import { BsArrowRight, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'
import { Link } from 'react-router-dom'




const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute z-[10] top-0 w-full'>
            <div className='flex flex-row-reverse md:flex-row bg-black'>
                <div className='w-[80%] md:w-[20%] h-auto '>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[90%] md:w-[75%] mx-auto'
                    />
                </div>
                <div className='w-[20%] md:w-[80%] flex md:flex-col flex-row justify-center items-center'>
                    <div className='w-full hidden md:h-[60%] bg1 rounded-bl-2xl md:flex'>
                        <div className='hidden w-[30%] text-white lg:flex mx-auto items-center justify-center'>
                            <div className='w-[15%] self-center'>
                                <BiPhoneCall fontSize={35} />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <p className='-mb-3'>Phone Number</p>
                                    <h5 className='text-[21px]'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className='hidden w-[30%] text-white lg:flex mx-auto items-center justify-center'>
                            <div className='w-[15%] self-center'>
                                <BiCalendar fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3 capitalize'>working days</p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                </h5>
                            </div>
                        </div>
                        <div className='hidden w-[40%] text-white  lg:flex mx-auto justify-center items-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3 capitalize'>
                                    Working hours
                                </p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[40%] flex items-center py-6'>
                        <nav className='w-full md:w-[80%] px-5 '>
                            <Navmenu />
                        </nav>
                        <div className='w-[20%] hidden md:block'>
                            <Link
                                className='flex justify-center transition ease-in-out delay-200 hover:translate-x-5 duration-300'
                                to={`/${btnName === 'view all services' ? 'services' : 'contact'}`}
                            >
                                <span className='capitalize text-red-600 text-[18px] font-semibold flex items-center space-x-2'>
                                    <p>{btnName ? btnName : 'FREE ESTIMATE'}</p>
                                    <BsArrowRight className='text-2xl font-bold' />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default HeaderTwo